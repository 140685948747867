import type { ReactNode } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import isFunction from "lodash/isFunction";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import type { ModalSizeType } from "@circle-react-uikit/ModalV2/ModalContent";

export interface ConfirmationModalProps {
  cancelText?: string;
  children?: ReactNode;
  confirmText?: string;
  confirmVariant?: "primary" | "circle" | "danger";
  contentPortalId?: string;
  disabled?: boolean;
  formId?: string;
  isOpen?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
  size?: ModalSizeType;
  title: string;
  titleSize?: "sm" | "md";
  dataTestId?: string;
  headerClassName?: string;
  footerClassName?: string;
}

export const ConfirmationModal = ({
  cancelText = t("cancel"),
  children,
  confirmText = t("confirm"),
  confirmVariant = "primary",
  contentPortalId = "",
  disabled = false,
  formId,
  isOpen = false,
  onCancel,
  onClose,
  onConfirm,
  size = "lg",
  title,
  titleSize = "sm",
  dataTestId,
  headerClassName = "",
  footerClassName = "",
}: ConfirmationModalProps) => {
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        isFunction(onClose) && onClose();
      }}
      contentPortalId={contentPortalId}
    >
      <Modal.Overlay />
      <Modal.Content size={size} wrapperClassName="!h-full">
        <Modal.Header className={headerClassName}>
          <Modal.Title size={titleSize}>{title}</Modal.Title>
          <Modal.HeaderActions>
            {isFunction(onClose) && (
              <Modal.CloseButton
                onClick={() => {
                  onClose();
                }}
              />
            )}
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body
          className={classNames({
            "!px-5": isMobileWebNewSpaceCustomizationUIEnabled,
          })}
        >
          {children}
        </Modal.Body>
        <Modal.Footer className={footerClassName}>
          <div className="flex items-stretch justify-end space-x-4">
            {(isFunction(onCancel) || isFunction(onClose)) && (
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  if (isFunction(onCancel)) return onCancel();
                  if (isFunction(onClose)) return onClose();
                }}
              >
                {cancelText}
              </Button>
            )}
            <Button
              type={formId ? "submit" : "button"}
              form={formId}
              variant={confirmVariant}
              disabled={disabled}
              onClick={() => {
                isFunction(onConfirm) && onConfirm();
              }}
              data-testid={dataTestId}
            >
              {confirmText}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
